<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">BUSINESS TRAVEL INSURANCE</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

				<div class="content">
					<h2>What is business travel insurance?</h2>
					<p class="mb-5">
						Business travel insurance provides cover specifically for those who are travelling abroad for work purposes.  
					</p>

					<h2>Do I need business travel insurance?</h2>
					<p class="mb-5">
						Adding business cover to your travel insurance policy will provide you with specific cover if you are travelling abroad for work purposes. For example, if you are taking your work laptop or tablet with you, a business travel insurance policy will provide cover for your business equipment (such as a laptop of tablet), if it is lost, stolen or damaged during your business trip. 
					</p>

					<h2>What does business travel insurance cover me for?</h2>
					<p class="mb-5">
						Covered2go’s business cover will provide cover for the following: 
						<ul>
							<li>
								You are covered to do non-manual work during your leisure, holiday or business trip 
								under this policy and it can be extended to cover manual work if you have paid an 
								appropriate additional premium and it is shown on your policy schedule.
							</li>
							<li>
								Reasonable additional business travel and accommodation costs for a business partner,
								director or employee from the UK to replace you at a pre-arranged meeting in the event
								of your medical incapacity to carry out your duties abroad up to £2,500.
							</li>
							<li>
								The cost of replacing lost, stolen damaged or destroyed business equipment (up to £1,000),
								samples or documentation (up to £500), while being carried with you during your trip.
							</li>
						</ul>
					</p>

					<h2>What type of business travel insurance do I need?</h2>
					<p class="mb-5">
						The type of business travel insurance you need will depend on the following:
					</p>
					<h2>How often do you travel?</h2>
					<p class="mb-5">
						If your business trip takes place once a year, you may be more suited to a <a href="https://www.covered2go.co.uk/single-trip-travel-insurance">single trip travel insurance</a> policy. However, if you travel regularly for business, you might benefit from an <a href="https://www.covered2go.co.uk/annual-trip-travel-insurance">annual multi trip policy</a> instead. The premiums may be dearer when purchasing an annual policy, however, it would work out more economical to pay this upfront, rather than paying for multiple single trip policies.  
					</p>
					<h2>Where are you travelling to?</h2>
					<p class="mb-5">
						Depending on where you are travelling to will depend on the area you will need to choose when getting your travel insurance quote. The areas we have are as follows: 
						<ul>
							<li><a href="https://www.covered2go.co.uk/uk-travel-insurance">UK</a></li>
							<li>Europe</li>
							<li>Australia and New Zealand</li>
							<li>Worldwide excluding USA, Canada, and Caribbean </li>
							<li>Worldwide including USA, Canada, and Caribbean</li>
						</ul>
						<!--If you are unsure which area your destination falls into <a href="https://www.covered2go.co.uk/which-area">click here</a> to find out which area you need to choose.-->
					</p>

					<h2>Does business travel insurance cover me like a standard policy would?</h2>
					<p class="mb-5">
						Business cover can be added to all Covered2go travel insurance policies, and as such, you can also benefit from the following:
						<ul>
							<li><a href="https://www.covered2go.co.uk/covid19-travel-insurance">Covid-19 cover*</a> (which includes up to £10,000 for cancellation / curtailment due to contracting Covid-19). </li>
							<li>Up to £10M medical expenses and repatriation (include cover if you contracted Covid-19 abroad)</li>
							<li>Cover for your personal possessions, including mobile phones when you choose our highest tier of cover</li>
							<li>24-hour emergency medical assistance</li>
						</ul>
					</p>

					<h2>Does business travel insurance have any exclusions?</h2>
					<p class="mb-5">
						As with most types of insurance, there are policy exclusions, such as:
						<ul>
							<li><a href="https://www.covered2go.co.uk/pre-existing-medical-conditions">Pre-existing medical conditions</a>: If you fall ill or become injured on your trip, your travel insurance policy may cover you for some of the medical costs.
                However, if you have <a href="https://www.covered2go.co.uk/pre-existing-medical-conditions">pre-existing medical conditions</a>, cover would not be provided, unless you’ve declared them before you travelled. </li>
							<li>Travel recommendations: There is no cover on any of our policies if you travel against the Foreign, Commonwealth and Development Office (FCDO), government, local authority, or medical advice.</li>
							<li>Claims resulting from intoxication: Even if you have the right cover in place, you won’t be able to claim for damages, or lost or stolen goods, if you were under the influence of alcohol or drugs.</li>
						</ul>
						For full policy exclusions, terms, and conditions, please take the time to read our <a href="https://www.covered2go.co.uk/policy-wordings-mul/">Policy Wordings</a>.
					</p>

					<h2>How much does a business travel insurance policy cost?</h2>
					<p class="mb-5">
						The overall premium you might pay for business travel insurance will depend on several factors, including:
						<ul>
							<li>Age: Travel insurance generally costs less the younger you are.</li>
							<li>Activities: Taking part in activities which are deemed as risky or dangerous like scuba diving or winter sports, will usually increase the premium due to the increased risk of becoming injured or losing your possessions.</li>
							<li>Destination: It’s more expensive to cover travels to certain destinations, such as the USA or Canada, due to the increased medical treatment costs and distance you have to travel.</li>
							<li>Trip length: The longer the trip, the longer you’ll need cover for, so premiums will increase for trips of longer durations. </li>
						</ul>
						Looking for business travel insurance? <a href="https://www.covered2go.co.uk/get-quote">Get a quote</a> online today.
						<br><br>
						*Please note, there is no cover on any of our policies if you travel against the Foreign, Commonwealth and Development Office (FCDO), government, local authority, or medical advice.
					</p>
				</div>
				
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'BusinessTravelInsurance',
	data() {
		return {

		}
	},
	methods: {

	},
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	}
};
</script>

<style lang="scss" scoped>
	@import 'src/styles/views/info-page';
</style>
